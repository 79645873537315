import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { Heading, Container, Text } from "@chakra-ui/react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const InfoPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle} container>
      <SEO title="Personvern" />
      <Container marginTop="20">
        <Heading size="xl" marginBottom="5">
          Personvern
        </Heading>

        <Text>
          Denne nettsiden bruker informasjonskapsler for å huske hvilket språk
          du foretrekker og om du ønsker mørkt eller lyst tema når du bruker
          den. Disse cookiene heter <code>chakra-ui-color-mode</code> og{" "}
          <code>gatsby-intl-language</code>.
        </Text>

        <Heading size="md" marginTop="10">
          Dine data
        </Heading>

        <Text size="lg">
          Informasjonen på denne siden serveres statisk, og vi lagrer ingen
          informasjon om deg hos oss. Kontaktskjemaet og innmeldingsskjemaet
          sender epost direkte til vår epost-adresse.
        </Text>
        <br />
        <br />
        <br />
        <br />
      </Container>
    </Layout>
  )
}

InfoPage.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
}

export default InfoPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
